import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet";
import { useEffect } from "react";

const PageNotFound = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const dir = localStorage.getItem('dir');
    const lang = localStorage.getItem('lang');
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [])
    return  <main className="main" style={{ direction: dir, textAlign: dir === 'rtl' ? 'right' : 'left' }}>
            <Helmet>
            <title>404 Page Not Found - {t('site_name')}</title>
            <meta name="description" content={`Oops! The page you are looking for cannot be found. Visit our homepage or explore other sections of {t('site_name')}.`} />
            
            <meta name="robots" content="noindex, nofollow" />
            <meta property="og:title" content={`404 Page Not Found - ${t('site_name')}`} />
            <meta property="og:description" content={`The page you're looking for doesn't exist. Head back to ${t('site_name')} homepage for more information.`} />
            <meta property="og:url" content={`${t('site_url')}/404`} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={`${t('site_url')}/static/images/error-404.png`} />
            
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={`404 Page Not Found - ${t('site_name')}`} />
            <meta name="twitter:description" content={`We couldn't find the page you're looking for. Please visit the ${t('site_name')} homepage to continue exploring.`} />
            <meta name="twitter:image" content={`${t('site_url')}/static/images/error-404.png`} />

            <link rel="canonical" href={`${t('site_url')}/404`} />
        </Helmet>

            <div className="container login-container">
                <div className="row">
                    <div className="col-lg-12 mx-auto">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <div className="heading mb-1" >
                                    <img src="/assets/images/Logo/car7_transparent_powered_by.png" style={{float: 'none', margin: 'auto'}}  width="111" height="44" alt="Carz7 Logo" />
                                    <h2 className="title mt-0">{t('page_not_found')}</h2>
                                    {
                                        lang === '' || lang === 'en'?
                                    
                                        <p>

                                        Thank you for reaching out. <br />
    We would like to inform you that the product you are interested in is currently unavailable.  <br />
    To assist you further and provide you with alternative options or updates on the availability of this product, please leave your name, number, and email. One of our dedicated sales representatives will get in touch with you shortly. Thank you for your understanding and patience.


                                        </p>:
                                        <p>
                                            شكرًا لتواصلك معنا. <br />
                                            نود إبلاغك بأن المنتج الذي تهتم به غير متوفر حاليًا. <br />
                                            لمساعدتك بشكل أكبر وتزويدك بخيارات بديلة أو تحديثات حول توفر هذا المنتج، يرجى ترك اسمك ورقم هاتفك وبريدك الإلكتروني. سيتواصل معك أحد ممثلي المبيعات لدينا قريبًا. شكرًا لتفهمك وصبرك.
                                        </p>
                                    }

                                    <button type="button" onClick={() => navigate('/')} className="btn btn-dark" style={{float: 'none', marginLeft: '40%'}}>{t('go_home')}</button>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
}

export default PageNotFound;
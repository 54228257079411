
import Autocomplete from "react-google-autocomplete";
import React, { useState, useEffect, useContext } from "react";
import { ConfigContext } from "../../Context/ConfigContext";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import AccountSiderBar from "./AccountSideBar";
import { useTranslation } from "react-i18next";
import Select from 'react-select'
const ManageAddress = () => {
  const { t } = useTranslation();
  const { apiURL, token } = useContext(ConfigContext);
  const dir = localStorage.getItem("dir");

  // Individual states for each form element
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [country, setCountry] = useState("");
  const [completeAddress, setCompleteAddress] = useState("");
  const [apartment, setApartment] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [mobileNumber1, setMobileNumber1] = useState("");
  const [mobileNumber2, setMobileNumber2] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [selected, setSelected] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [countryId, setCountryId] = useState(0);
  const [addressLat, setAddressLat] = useState("");
  const [addressLng, setAddressLng] = useState("");
  const [addressId, setAddressId] = useState("");

  const [countries, setCountries] = useState([]);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [CountryAlphaCode, setCountryAlphaCode] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    GetCountries();
  }, []);

  const GetCountries = async () => {
    try {
      const response = await axios.get(`${apiURL}/Home/GetCountries`);
      const data = response.data;
      if (data) {
        setCountries(data.data);
      } else {
        console.log("No Countries Found", response.data.message);
      }
    } catch (err) {
      console.log("Something Went Wrong", err);
    }
  };

  const handleCountryChange = (e) => {
    const countryValue = e.target.value;
    const currentCountry = countries.find(
      (country) => country.country === countryValue
    );

    setCountry(countryValue);
    if (currentCountry) {
      setCountryId(currentCountry.country_id);
      setCountryCode(currentCountry.country_alpha1_code);
    }
  };

  const AddUpdateCustomerAddress = async (e) => {
    e.preventDefault();
    setSaving(true);

    try {
      const headers = {
        token: token,
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const addressData = {
        customer_id: localStorage.getItem("customer_id"),
        first_name: firstName,
        last_name: lastName,
        company_name: companyName,
        country: country,
        complete_address: completeAddress,
        apartment: apartment,
        city: city,
        state: state,
        pincode: pincode,
        mobile_number_1: mobileNumber1,
        mobile_number_2: mobileNumber2,
        contact_email: contactEmail,
        selected: selected ? 1 : 0,
        country_code: countryCode,
        country_id: countryId,
        address_lat: "address_lat",
        address_lng: "address_lng",
      };

      const response = await axios.post(
        `${apiURL}/Account/AddAddress`,
        addressData,
        { headers }
      );

      if (response.data.success) {
        Swal.fire({
          title: "Successfully",
          text: response.data.message,
          icon: "success",
          confirmButtonText: "Okay",
        });
        if (!addressId) {
          resetFields();
        }
        navigate('/MyAccount/Addresses')
      } else {
        Swal.fire({
          title: "Error",
          text: response.data.message,
          icon: "error",
          confirmButtonText: "Okay",
        });
      }
      setSaving(false);
    } catch (error) {
      setSaving(false);
      setErrorMessage("An error occurred Adding/Updating Address.");
    }
  };

  const resetFields = () => {
    setFirstName("");
    setLastName("");
    setCompanyName("");
    setCountry("");
    setCompleteAddress("");
    setApartment("");
    setCity("");
    setState("");
    setPincode("");
    setMobileNumber1("");
    setMobileNumber2("");
    setContactEmail("");
    setSelected(false);
    setCountryCode("");
    setCountryId(0);
    setAddressLat("");
    setAddressLng("");
    setAddressId("");
  };

  return (
    <main>
      <div className="page-header">
        <div className="container d-flex flex-column align-items-center">
          <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <div className="container">
              <ol className="breadcrumb" dir={dir}>
                <li className="breadcrumb-item">
                  <NavLink to={"/"}>{t("home")}</NavLink>
                </li>
                <li className="breadcrumb-item">
                  <NavLink to={() => {}}>{t("my_account")}</NavLink>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t("manage_address")}
                </li>
              </ol>
            </div>
          </nav>
          <h1>{t("manage_address")}</h1>
        </div>
      </div>
      <div
        className={`container account-container custom-account-container `}
        dir={dir}
      >
        <div className="row">
          <AccountSiderBar />
          <div
            className={`col-lg-9 order-lg-last order-1 tab-content ${
              dir === "rtl" ? "text-right" : ""
            }`}
            dir={dir}
          >
            <div className="tab-pane fade show active" id="address" role="tabpanel">
              <div className="address account-content mt-0 pt-2">
                <h4 className="title">
                  <NavLink
                    to={`/MyAccount/Addresses`}
                    className={`link-to-tab ${dir === "rtl" ? "text-right" : ""}`}
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <i className="icon-left-open"></i> {t("back_to_address")}
                  </NavLink>
                </h4>
                <form className="mb-2">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          {t("first_name")} <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          {t("last_name")} <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>
                          {t("phone")} <span className="required">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          value={mobileNumber1}
                          onChange={(e) => setMobileNumber1(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>
                          {t("email")} <span className="required">*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          value={contactEmail}
                          onChange={(e) => setContactEmail(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>{t("company")}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </div>
                  
                 
                  <div className="form-group">
                    <label>{t("address")}</label>
                    <textarea
                      type="text"
                      className="form-control"
                      value={completeAddress}
                      onChange={(e) => setCompleteAddress(e.target.value)}
                      required
                    />
                  </div>

                  <div className="form-group">
                  <label>{t("town_city")}</label>
                  <Autocomplete
                      apiKey={`AIzaSyCnDyGNIfJgUAA3evE-n805f1u16Doq6Qs`}
                       className="form-control"
                       value={city}
                       onChange={(e) => setCity(e.value)}
                      onPlaceSelected={(place) => {
                        
                        var comps = place.address_components;
                        for(var i = 0; i < comps.length; i++){
                          console.log("Types",comps) 
                          if(comps[i].types[0] === 'locality'){ 
                            setCity(comps[i].long_name); 
                          }
                          if(comps[i].types[0] === 'administrative_area_level_1'){ 
                            setState(comps[i].long_name);  
                          }
                          if(comps[i].types[0] === 'country'){
                            
                          }
                        }
                      }}
                    />
                  </div>
                  <div className="select-custom">
                    <label>
                      {t("country")} <span className="required">*</span>
                    </label>
                   
                    <Select  options={countries} onChange={(item) => {
                      setCountryId(item.country_id);
                      setCountryCode(item.phone_code);
                      setCountry(item.country)
                      setCountryAlphaCode(item.country_alpha1_code)
                      console.log(item);
                    }}/>
                  </div>

                  <div className="form-group">
                    <label>{t("zipcode")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="pincode"
                      value={pincode}
                      onChange={(e) => setPincode(e.target.value)}
                      required
                    />
                  </div>

                  <div className="form-footer mb-0">
                    <div className="form-footer-right d-flex justify-content-between">
                      <span>
                        {errorMessage && (
                          <div id="error" className="alert alert-danger">
                            {errorMessage}
                          </div>
                        )}
                        {successMessage && (
                          <div id="success" className="alert alert-success">
                            {successMessage}
                          </div>
                        )}
                      </span>
                      {saving ? (
                        <button type="button" className="btn btn-default">
                          {t("Saving Address...")}
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={AddUpdateCustomerAddress}
                        >
                          {t("save_address")}
                        </button>
                      )}
                    </div>
                  </div>
                  {/* Additional fields and buttons */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ManageAddress;




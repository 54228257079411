import React, { Component, useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import MobileCartSideBar from "../Components/MobileCartSideBar";
import { ConfigContext } from "../Context/ConfigContext";
import SearchBar from "../Components/SearchBar/SearchBar";
import { useTranslation } from "react-i18next";
import axios from "axios";
import slugify from "slugify";
import {
  RiFacebookBoxFill,
  RiInstagramFill,
  RiTwitterXFill,
} from "react-icons/ri";
import MyGarage from "./MyGarage";
const Header = () => {
  
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dir = localStorage.getItem("dir");
  const {
    setSideBarStatus,
    treeCategories,
    gdcURL,
    setCategories,
    CartItemsQty,
    lang,
    setLang,
    CartAmount,
  } = useContext(ConfigContext);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleLangChange = (lang) => {
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
    setLang(lang);
    window.location.reload();
  };
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    checkCountry();
}, [])
const {isUserInternational, setIsUserInternational} = useContext(ConfigContext);


  const checkCountry = async() => {
      try {
          // Get IP information from ipinfo.io
          const response = await axios.get("https://ipinfo.io/json?token=c56ea131e1f129"); // Replace 'YOUR_TOKEN' with ipinfo API token
          const data = response.data;
          
          if (data.country === "AE") {
            setIsUserInternational(false);
            
            
          }else{
            setIsUserInternational(true);
          }
          
      }catch (error) {
          console.error("Error fetching location:", error);
      }   
  }
  useEffect(() => {
    fetch(
      `${gdcURL}/Categories/${
        localStorage.getItem("pc_id") !== null &&
        localStorage.getItem("pc_id") !== "" &&
        localStorage.getItem("pc_id") !== 0
          ? "GetCategoriesByCar"
          : "GetCategories"
      }?pc_id=${localStorage.getItem("pc_id")}&lang=${lang}`
    )
      .then((res) => res.json())
      .then((data) => {
        setCategories(data.data);
      });
  }, [null]);

  const GetSubMenu = ({ childrens }) => {
    if (childrens.length === 0) {
      return null; // No submenu to render
    }

    return (
      <ul
        className="submenu"
        style={
          dir === "rtl" ? { left: "unset", right: "100%" } : { left: "100%" }
        }>
        {childrens.map((child, index) => (
          <li key={`${child.STR_ID}-${index}`}>
            <NavLink
              to={`/Spare-Parts/${slugify(child.STR_NODE_NAME)}-${
                child.STR_ID
              }`}>
              {child.STR_NODE_NAME}{" "}
              {child.children.length > 0 && (
                <i
                  style={{ float: dir === "rtl" ? "left" : "right" }}
                  className={`fas ${
                    dir === "rtl" ? "fa-chevron-left" : "fa-chevron-right"
                  }`}></i>
              )}
            </NavLink>
            {child.children.length > 0 && (
              <GetSubMenu childrens={child.children} />
            )}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <header className="header">
      <div className="header-top darkColor" style={{ color: "#FFF" }}>
        <div className="container">
          <div className="header-left d-none d-sm-block"></div>
          {/* End .header-left */}
          <div className="header-right header-dropdowns ml-0 ml-sm-auto w-sm-100">
            <div className="header-dropdown dropdown-expanded d-none d-lg-block">
              <NavLink to="#">{"Links"}</NavLink>
              <div className="header-menu"></div>
              {/* End .header-menu */}
            </div>
            {/* End .header-dropown */}
            <span className="separator" />
            <div className="header-dropdown mr-auto mr-sm-3 mr-md-0">
              {lang && lang === "en" ? (
                <NavLink to="#">
                  {" "}
                  <i className="flag-us flag" /> ENG{" "}
                </NavLink>
              ) : (
                <NavLink to="#">
                  {" "}
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Logo/language-6.png`}
                    width={"16px"}
                    height={"16px"}
                    alt=""
                  />{" "}
                  {t("arabic")}{" "}
                </NavLink>
              )}

              <div className="header-menu">
                <ul>
                  <li>
                    <NavLink to="#" onClick={() => handleLangChange("en")}>
                      <i className="flag-us flag mr-2" />
                      {t("english")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="#" onClick={() => handleLangChange("ar")}>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/Logo/language-6.png`}
                        width={"16px"}
                        height={"16px"}
                        alt=""
                      />
                      {t("arabic")}
                    </NavLink>
                  </li>
                </ul>
              </div>
              {/* End .header-menu */}
            </div>
            {/* End .header-dropown */}
            <div className="header-dropdown mr-auto mr-sm-3 mr-md-0">
              <NavLink to="#">
                {t(`${localStorage.getItem("currency") ?? "aed"}`)}
              </NavLink>
              <div className="header-menu">
                <ul>
                  <li>
                    <NavLink to="#">
                      <span
                        onClick={() => {
                          localStorage.setItem("currency", "aed");
                          window.location.reload();
                        }}>
                        {t("aed")}
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="#">
                      <span
                        onClick={() => {
                          localStorage.setItem("currency", "usd");
                          window.location.reload();
                        }}>
                        {t("usd")}
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
              {/* End .header-menu */}
            </div>
            {/* End .header-dropown */}
            <span className="separator" />
            <div className="social-icons">
              <NavLink to="#" className="social-icon" target="_blank">
                <RiFacebookBoxFill />
              </NavLink>
              <NavLink to="#" className="social-icon" target="_blank">
                <RiTwitterXFill />
              </NavLink>
              <NavLink to="#" className="social-icon" target="_blank">
                <RiInstagramFill />
              </NavLink>
            </div>
            {/* End .social-icons */}
          </div>
          {/* End .header-right */}
        </div>
        {/* End .container */}
      </div>
      {/* End .header-top */}
      <div className="header-middle lightColor" style={{ borderTop: "0px" }}>
        <div className="container">
          <div className="header-left col-lg-2 w-auto pl-0">
            <button
              className="mobile-menu-toggler text-primary mr-2"
              type="button">
              <i className="fas fa-bars" />
            </button>
            <NavLink to="/" className="logo">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/Logo/car7_transparent_powered_by.png`}
                width={111}
                height={44}
                alt="Carz7 Logo"
              />
            </NavLink>
          </div>
          {/* End .header-left */}

          <div className="header-right w-lg-max">
            <SearchBar />
            {/* End .header-search */}

            <div
              className="darkColor pl-3 pr-3 pt-1 pb-1"
              style={{ display: "flex", alignItems: "center" }}>
              <span
                
                className="header-icon"
                title="Gatage"
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginRight: "20px",
                  cursor: 'pointer'
                }} // Add margin to separate links
              >
                <svg
                  className="header-nav__icon base-svg mr-2"
                  role="img"
                  style={{
                    width: "30px",
                    height: "30px",
                    fill: "rgb(191, 203, 210)",
                  }}>
                  <use href="/assets/images/icon-sprite-bw.svg#sprite-garage-car-icon-bw"></use>
                </svg>
                <div style={{ color: "rgb(191, 203, 210)" }} id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <div style={{ fontSize: 'medium',fontWeight: '600' }}>{t("my_cars")}</div>
                  <div style={{ fontSize: "smaller",marginTop: '-5px'}}>{t('add_your_car')}</div>
                </div>
                
                  <MyGarage />
                  
              </span>

              <NavLink
                to={
                  localStorage.getItem("customer_id") ? "/MyAccount" : "Signup"
                }
                className="header-icon"
                title="login"
                style={{ marginRight: "20px" }} // Add margin for spacing
              >
                <i className="icon-user-2" style={{ color: "#bfcbd2" }}></i>
              </NavLink>

              {/* Uncomment if needed */}
              {/* <NavLink to="/Wishlist" className="header-icon" title="wishlist">
        <i className="icon-wishlist-2" style={{ color: '#bfcbd2' }} />
    </NavLink> */}

              <NavLink
                to="/Cart"
                title="Cart"
                style={{
                  position: "relative",
                  display: "inline-block",
                  marginRight: "20px",
                }} // Add margin
              >
                <i
                  className="minicart-icon"
                  style={{ borderColor: "#bfcbd2", marginTop: "-3px" }}
                />
                <span
                  className="cart-count badge-circle"
                  style={{ marginTop: "-3px" }}>
                  {CartItemsQty}
                </span>
              </NavLink>

              <NavLink
                to="/Cart"
                title="Cart"
                style={{ position: "relative", display: "inline-block" }}>
                <div
                  className="ml-3"
                  style={{
                    color: "#e5eaed",
                    fontSize: "28px",
                    fontFamily: "Montserrat,Arial,sans-serif",
                  }}>
                  {CartAmount}
                </div>
              </NavLink>
            </div>

            <div className={`dropdown cart-dropdown show`}>
              {/* <div className="cart-overlay" style={SideCartStatus === true?{position: 'fixed'}: {position: 'unset'}}/> */}
              <MobileCartSideBar />
              {/* End .dropdown-menu */}
            </div>
            {/* End .dropdown */}
          </div>
          {/* End .header-right */}
        </div>
        {/* End .container */}
      </div>
      {/* End .header-middle */}
      <div
        className="header-bottom  d-none d-lg-block darkColor"
        style={{
          borderBottom: "1px solid rgb(226, 226, 226)",
          marginBottom: "10px",
        }}>
        <div className="container">
          <nav
            className="main-nav w-100"
            style={{ textAlign: dir === "rtl" ? "right" : "left" }}>
            <ul className="menu">
              <li
                className="menuItemStyle"
                onClick={() => setSideBarStatus(true)}
                style={
                  dir === "rtl"
                    ? { float: "right", marginRight: "20px", color: "#FFF",  }
                    : { float: "left",  }
                }>
                <label
                  style={{
                    color: "rgb(191, 203, 210)",
                    paddingTop: "20px",
                    fontWeight: 700,
                    fontSize: "12px",
                  }}
                  htmlFor="sidebar-toggle"
                  className="main-nav navbar-toggler sidebar-toggler"
                  aria-expanded="false"
                  aria-label="Toggle navigation">
                  <svg
                    className="header-nav__icon base-svg mr-2"
                    role="img"
                    style={{
                      width: "25px",
                      height: "25px",
                      fill: "rgb(208, 213, 215)",
                    }}>
                    <use href="/assets/images/icon-sprite-bw.svg#sprite-car-icon-full-bw"></use>
                  </svg>
                  {t("car_parts")}
                </label>
              </li>
              <li
                className="menuItemStyle"
                style={
                  dir === "rtl"
                    ? {
                        float: "right",
                        marginRight: "20px",
                        color: "#FFF",
                        display: "none",
                        borderLeft: '1px solid #CCC' 
                        
                      }
                    : { float: "left", display: "none",  borderLeft: '1px solid #CCC' }
                }>
                <NavLink to="#" style={{ color: "#bfcbd2" }}>
                  <img
                    src="/assets/icons/car-parts.png"
                    className="menuIcon"
                    alt="Batteries"
                    style={{ height: "30px", marginTop: "-8px" }}
                  />
                  {t("car_parts")}
                </NavLink>
                <ul className="submenu">
                  {treeCategories.map((category, index) => (
                    <li key={`${category.STR_ID}-${index}`}>
                      <NavLink to="#">{category.STR_NODE_NAME}</NavLink>
                      <GetSubMenu childrens={category.children} />
                    </li>
                  ))}
                </ul>
              </li>

              <li
                className="menuItemStyle"
                style={
                  dir === "rtl"
                    ? { float: "right", marginRight: "20px", borderLeft: '1px solid #EEE' }
                    : { float: "left",   borderLeft: '1px solid #EEE' }
                }>
                <NavLink
                  to="/Spare-Parts/Battery-100042"
                  style={{ color: "#bfcbd2" }}>
                  <svg
                    className="header-nav__icon base-svg"
                    role="img"
                    style={{
                      width: "25px",
                      height: "25px",
                      fill: "rgb(208, 213, 215)",
                    }}>
                    <use href="/assets/images/icon-sprite-bw.svg#sprite-electrik-icon-bw"></use>
                  </svg>
                  {t("batteries")}
                </NavLink>
              </li>

              <li
                className="menuItemStyle"
                style={
                  dir === "rtl"
                    ? { float: "right", marginRight: "20px", }
                    : { float: "left", }
                }>
                <NavLink
                  to="/Spare-Parts/Oil-706233"
                  style={{ color: "#bfcbd2" }}>
                  <svg
                    className="header-nav__icon base-svg"
                    role="img"
                    style={{
                      width: "25px",
                      height: "25px",
                      fill: "rgb(208, 213, 215)",
                    }}>
                    <use href="/assets/images/icon-sprite-bw.svg#sprite-oil-icon-bw"></use>
                  </svg>
                  {t("oil")}
                </NavLink>
              </li>
              {/* <li className="menuItemStyle" style={ dir === "rtl" ? { float: "right", marginRight: "20px" } : { float: "left" } }>
                <NavLink to="/Spare-Parts/Accessories-100733" style={{color: '#bfcbd2'}}><img src="/assets/icons/tools.png" className="menuIcon" alt="Batteries"/>{t("Accessories")}</NavLink>
              </li> */}
              <li className="menuItemStyle" style={ dir === "rtl" ? { float: "right", marginRight: "20px",  } : { float: "left",  } }>
                <NavLink
                  to="/Spare-Parts/Accessories-100733"
                  style={{ color: "#bfcbd2" }}>
                  <svg
                    className="header-nav__icon base-svg"
                    role="img"
                    style={{
                      width: "25px",
                      height: "25px",
                      fill: "rgb(208, 213, 215)",
                    }}>
                    <use href="/assets/images/icon-sprite-bw.svg#sprite-misc-icon-bw"></use>
                  </svg>
                  {t("accessories")}
                </NavLink>
              </li>

              <li
                className="menuItemStyle"
                style={
                  dir === "rtl"
                    ? { float: "right", marginRight: "20px",  }
                    : { float: "left",  }
                }>
                <NavLink
                  to="/Spare-Parts/Tyre-103100"
                  style={{ color: "#bfcbd2" }}>
                  <svg
                    className="header-nav__icon base-svg"
                    role="img"
                    style={{
                      width: "25px",
                      height: "25px",
                      fill: "rgb(208, 213, 215)",
                    }}>
                    <use href="/assets/images/icon-sprite-bw.svg#sprite-tyres-icon-bw"></use>
                  </svg>
                  {t("tyres")}
                </NavLink>
              </li>
              <li
                className="menuItemStyle"
                style={
                  dir === "rtl"
                    ? { float: "right", marginRight: "20px",  }
                    : { float: "left",  }
                }>
                <NavLink
                  to="/Spare-Parts/Filters-100005"
                  style={{ color: "#bfcbd2" }}>
                  <svg
                    className="header-nav__icon base-svg"
                    role="img"
                    style={{
                      width: "25px",
                      height: "25px",
                      fill: "rgb(208, 213, 215)",
                    }}>
                    <use href="/assets/images/icon-sprite-bw.svg#sprite-filters-icon-bw"></use>
                  </svg>
                  {t("filters")}
                </NavLink>
              </li>
              <li
                className="menuItemStyle"
                style={
                  dir === "rtl"
                    ? { float: "right", marginRight: "20px",  }
                    : { float: "left",  }
                }>
                <NavLink
                  to="/Spare-Parts/Braking-System-100006"
                  style={{ color: "#bfcbd2" }}>
                  <svg
                    className="header-nav__icon base-svg"
                    role="img"
                    style={{
                      width: "25px",
                      height: "25px",
                      fill: "rgb(208, 213, 215)",
                    }}>
                    <use href="/assets/images/icon-sprite-bw.svg#sprite-brackes-icon-bw"></use>
                  </svg>

                  {t("brakes")}
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
        {/* End .container */}
      </div>
      
      {/* End .header-bottom */}
    </header>
  );
};

export default Header;

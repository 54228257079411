import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'

import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../../Context/ConfigContext';
import { useParams } from 'react-router-dom';
import PartsCard from '../../../Components/Cards/PartsCard';
import { ToastContainer, toast } from 'react-toastify';
import {EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton, XIcon}from "react-share";
import FooterOffers from "../../Home/FooterOffers";
import OwlCarousel from "react-owl-carousel";
import "react-multi-carousel/lib/styles.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import slugify from 'slugify';
import PartImages from './PartImages';
import CarSeachBoxHorizontal from '../../../Layout/CarSeachBoxHorizontal';
import OESection from './OESection';
import { AddToRecent } from '../../../Helper/RecentCart';
import RecentViews from '../../Home/RecentViews';
import Reviews from './Reviews';
import RelatedCategories from './RelatedCategories';
import CompatibilityCars from './CompatibilityCars';
import EquivalentParts from './EquivalentParts';
import { AddToCart } from '../../../Helper/MyCart';
import { AddToCartNotifier } from '../../../Components/CartComponents';
import PriceComparison from './PriceComparison';
import {  RiFacebookBoxLine, RiLinkedinBoxFill, RiMailFill,  RiTwitterXFill, RiWhatsappFill } from 'react-icons/ri';
import { Helmet } from 'react-helmet';
const PartDetails = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const locale = localStorage.getItem('lang');
    const dir = localStorage.getItem('dir');
    
    const {gdcURL, apiURL, PCID, setCategories, categories, setCartItemsQty, currency, isUserInternational} = useContext(ConfigContext);
    
    const { part_slug } = useParams();
    const ART_ID = part_slug.split('-').slice(-1);
    const SUP_ID = part_slug.split('-')[part_slug.split('-').length - 2];
    const STR_ID = part_slug.split('-')[part_slug.split('-').length - 3];
    const [loading, setLoading] = useState(true);
    const [InvalidCarMatchWithPart, setInvalidCarMatchWithPart] = useState(localStorage.getItem('car_info')?true:false);
    const [partInfo, setpartInfo] = useState();
    
    const [crosses, setCrosses] = useState();
    const [loadingCrosses, setLoadingCrosses] = useState(false);
    const car_info = localStorage.getItem('car_info')?JSON.parse(localStorage.getItem('car_info')): [];
    const [qtyOptions, setQTYOptions] = useState([]);
    const [selectQty, setSelectedQty] = useState(1);
    const location = useLocation();

    const [price, setPrice] = useState(0);
    const [priceDisplay, setPriceDisplay] = useState('...');
    const incrementQty = () => {
      setSelectedQty(prevQty => prevQty + 1);
    };
  
    const decrementQty = () => {
      setSelectedQty(prevQty => (prevQty > 1 ? prevQty - 1 : 1)); // Assuming quantity can't be less than 1
    };
    useEffect(() => {
      checkCountry();
    }, [isUserInternational])
    const checkCountry = () => {
      if(isUserInternational && partInfo && partInfo[0]){
              
        setPTINTL(partInfo[0].PT_INTL) 
      }else{
        setPTINTL(1);
      }
    }
    useEffect(() => {
      
        GetPartInfo();
        var tqtys = [];
        for(var i = 1; i < 100; i++){
            tqtys.push({value: i, label: `${i}`});
        }
        setQTYOptions(tqtys)
    }, [location])
    const [PTINTL, setPTINTL] = useState(0);
   const GetPartInfo = () => {

        setLoading(true);
        fetch(`${gdcURL}/Parts/GetPartInfo?art_number=&sup_id=${SUP_ID}&art_id=${ART_ID}&lang=en`)
          .then((res) => res.json())
          .then((data) => {
            
            setpartInfo(data.data);
            
            GetPartCrossRef(data.data[0].ART_ID);
            checkCountry();
            
            setLoading(false)
            var row = data.data[0];
            GetPrice(row.ART_ARTICLE_NR.replaceAll('-','').replaceAll(' ',''));
            // console.log('AddToRecent',[row.ART_ID,row.ART_ARTICLE_NR, row.ART_PRODUCT_NAME, 0, 0,  1, row.MEDIA_SOURCE, window.location.href])
           
             AddToRecent(row.ART_ID,row.ART_ARTICLE_NR, row.ART_SUP_BRAND + ' ' +row.ART_PRODUCT_NAME, 0, 0,  1, row.MEDIA_SOURCE, window.location.href, SUP_ID);
          })
   }


    
    const GetPartCrossRef = (art_id) => {
       
        fetch(`${gdcURL}/Parts/GetPartCrossRef?lang=en&art_id=${art_id}&lang=en`)
        .then((res) => res.json())
        .then((data) => {
            setCrosses(data.data);
            
            setLoadingCrosses(false)
        })
    }

    const handleAddToCart = async(part) => {
      var part_name = `${part.ART_SUP_BRAND} ${part.ART_PRODUCT_NAME}`;
      const partImg = part.MEDIA_SOURCE ? `${gdcURL}/images/${part.MEDIA_SOURCE}` : `${apiURL}/public/placeholder_category.jpeg`;
      
      const url = window.location.href;//`/Spare-Parts/Details/${slugify(`${part.PRODUCT_GROUP}`.toLowerCase())}-${slugify(`${part.ART_ARTICLE_NR}`.toLowerCase())}-${STR_ID}-${slugify(`${part.SUP_ID}`.toLowerCase())}-${slugify(`${part.ART_ID}`.toLowerCase())}`;
      await AddToCart(part.ART_ID,part.ART_ARTICLE_NR.replaceAll('-', '').replaceAll(' ', '').replaceAll('.', ''), part_name, part.mrp??0, price,  1, partImg, url);
      setCartItemsQty();
      
      
      toast.success(<AddToCartNotifier item_name={part_name}  item_img={partImg}/>, {
        onClose: () => {
         
        }
      })
      
      
    }
    const GetPrice = (part_number) => {

      fetch(`${gdcURL}/Parts/GetPrice?number=${part_number}&vendor_id=1&lang=${locale??'en'}&currency=${currency}`)
      .then((res) => res.json())
      .then((response) => {
       
        setPrice(response.data.pricing.price);
        setPriceDisplay(response.data.pricing.price_display);
       
      })
    }
   const invalidPartMatch = (value) => {
    
    setInvalidCarMatchWithPart(value);
   }
   useEffect(() => {

   }, [InvalidCarMatchWithPart])
    return (
        <main className="main">
          <Helmet>
            {loading === false  && partInfo && <title>{partInfo && partInfo[0].ART_SUP_BRAND} {partInfo && partInfo[0].ART_ARTICLE_NR} {partInfo && partInfo[0].ART_PRODUCT_NAME} </title>}
            <meta name="keywords" content={`${partInfo && partInfo[0].ART_SUP_BRAND}, ${partInfo && partInfo[0].ART_ARTICLE_NR}, ${partInfo && partInfo[0].ART_PRODUCT_NAME} `} />
        </Helmet>
          <CarSeachBoxHorizontal />
        {
          <div className="container">

          <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <ol className="breadcrumb" dir={dir}>
              <li className="breadcrumb-item">
                <NavLink to={'/'}>
                  <i className="icon-home" />
                </NavLink>
              </li>
              {partInfo ? (
                <li className="breadcrumb-item">
                  <NavLink to={() => {}}>
                    {partInfo[0].ART_PRODUCT_NAME}{" "}
                  </NavLink>
                </li>
              ) : (
                <li className="breadcrumb-item">
                  <NavLink to={() => {}}>...</NavLink>
                </li>
              )}
            </ol>
          </nav>
          {loading === false  && localStorage.getItem('car_info') && InvalidCarMatchWithPart && <div className='alert alert-danger'>
          This part doesnt fit in  {car_info && car_info[0].TYPEL}
          </div>}
          <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          />
          <div className="product-single-container product-single-default">
            <div className="cart-message d-none">
              <strong className="single-cart-notice">
                
              </strong>
              <span>has been added to your cart.</span>
            </div>
            <div>
                <div className='col-md-12'>
                <h3 className="product-title mb-2 mt-2"   >
                {partInfo && partInfo[0].ART_SUP_BRAND} {partInfo && partInfo[0].ART_ARTICLE_NR} {partInfo && partInfo[0].ART_PRODUCT_NAME} 
                </h3>
                </div>
                <div className='col-md-12'>
                {
                    partInfo && partInfo[0] && partInfo[0].ARTICLE_CRITERIA !=null && partInfo[0].ARTICLE_CRITERIA.split(';') && partInfo[0].ARTICLE_CRITERIA.split(';').map((criteria, indixSub) => {
                        var crRow = criteria.split(':');
                        return <span key={`top-spec-${indixSub}`}> {crRow[0]} {crRow[1]} </span>
                    })
                  }
                </div>
                <div className='col-md-12 mt-1'>
                <div className="ratings-container">
                  {partInfo && <span className="mt-1 ml2 mr-2">{t('article_number', {value: partInfo[0].ART_ARTICLE_NR})}</span>}
              
                  <div className="product-ratings">
                    <span className="ratings" style={{ width: "60%" }} />
                    {/* End .ratings */}
                    <span className="tooltiptext tooltip-top" />
                  </div>
                  {/* End .product-ratings */}
                  <NavLink to={() => {}} className="rating-link">
                    ( 6 Reviews )
                  </NavLink>
                  {/* End .product-ratings */}
                </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-5 col-md-6 product-single-gallery">
                {partInfo && <PartImages ART_ID={partInfo[0].ART_ID}/>}
                </div>

              {/* End .product-single-gallery */}
              <div className="col-lg-3 col-md-6 product-single-details">
                {/* End .price-box */}
                <div className="product-desc ml-0">
                  <div
                    className="tab-pane fade show active"
                    id="product-desc-content"
                    role="tabpanel"
                    aria-labelledby="product-tab-desc"
                  >
                    <div className="product-desc-content">
                    {
                partInfo && partInfo.map((part, index) => {
                    return <div key={`info-spec-${index}`}> 
                    <h5 style={{fontSize: '18px'}}>{t('specification')}</h5>
                    <table className="table" dir={dir} style={{textAlign: dir === 'rtl'? 'right': 'left', fontSize: '12px', color: '#333'}}>
                    <tbody>
                    {
                        part.ARTICLE_CRITERIA && part.ARTICLE_CRITERIA.split(';').map((criteria, indixSub) => {
                            var crRow = criteria.split(':');
                            return <tr key={`info-tr-${indixSub}`}><td><span style={{fontWeight: '500'}}>{crRow[0]}</span></td><td><span>{crRow[1]}</span></td></tr>
                        })
                    }
                    </tbody>
                    </table>
                    </div>
                })
            }
                    </div>
                    {/* End .product-desc-content */}
                  </div>
                </div>
                {/* End .product-desc */}
                <ul className="single-info-list ml-2" >
                  <li className="mr-5">
                  {t('part_number')} :
                    <strong>
                      {partInfo && partInfo[0].ART_ARTICLE_NR}
                    </strong>
                  </li>
                  <li>
                  {t('category')}:{" "}
                    <strong>
                      <NavLink to={() => {}} className="product-category">
                        {partInfo && partInfo[0].ART_PRODUCT_NAME}
                      </NavLink>
                    </strong>
                  </li>
                  <li>
                  {t('brand')}:{" "}
                    <strong>
                      <NavLink to={() => {}} className="product-category">
                        {car_info && car_info[0].ART_SUP_BRAND}
                      </NavLink>
                    </strong>
                    ,
                  </li>
                  <li> {t('condition')}:{" "} <strong> <NavLink to={() => {}} className="product-category"> {t('new')} </NavLink> </strong> </li>
                  
                </ul>
                
                {/* End .product-action */}
                <hr className="divider mb-0 mt-0" />
                <div className="product-single-share mb-3">
                  <label className="sr-only">{t('share')}:</label>
                  <div className="social-icons mr-2">
                    <FacebookShareButton url={window.location.href}>
                    <i className="social-icon  fab" target="_blank" title="Facebook" >
                      <RiFacebookBoxLine />
                      </i>
                    </FacebookShareButton>
                    <TwitterShareButton url={window.location.href}>
                    <i className="social-icon  fab" target="_blank" title="Twitter" >
                      <RiTwitterXFill />
                      </i>
                    </TwitterShareButton>
                    <LinkedinShareButton url={window.location.href}>
                    <i className="social-icon  fab" target="_blank" title="Linkedin" >
                      <RiLinkedinBoxFill />
                    </i>
                    </LinkedinShareButton>
                    <WhatsappShareButton url={window.location.href}>
                    <i className="social-icon  fab" target="_blank" title="WhatsApp" >
                      <RiWhatsappFill />
                    </i>
                    </WhatsappShareButton>
                    <EmailShareButton url={window.location.href}>
                    <i className="social-icon  fab" target="_blank" title="Mail" >
                      <RiMailFill />
                    </i>
                    </EmailShareButton>
                    
                  </div>
                  {/* End .social-icons */}
                  <NavLink
                    to={() => {}}
                    className="btn-icon-wish add-wishlist"
                    title={t('add_to_wishlist')}
                    onClick={() => this.handleAddToWishlist(partInfo)}
                  >
                    <i className="icon-wishlist-2" />
                    <span>{t('add_to_wishlist')}</span>
                  </NavLink>
                </div>
                {/* End .product single-share */}
              </div>
              <div className='col-lg-4 col-md-6'>
              <div className="product-action">
                  <div className='row'>
                    <div className='col-md-12 mb-2'>
                    {price > 0 && partInfo && partInfo[0] && PTINTL > 0 &&<span ><i style={{color: 'rgb(11, 207, 28)'}} className="far fa-dot-circle"></i> {t('in_stock')}</span>}
                    {price <= 0 && <h1 className='mt-4' >{t('out_of_stock')}</h1>}
                    {price > 0 && partInfo && partInfo[0] && PTINTL > 0 && <h1 className='mt-4' style={{lineHeight: 0}}> {priceDisplay}</h1>}
                    {price > 0 && partInfo && partInfo[0] && PTINTL > 0 &&  <small>{t('price_included_vat_excluded_delivery')}</small>}
                    {partInfo && partInfo[0] && PTINTL === 0 && <span className='alert alert-danger mt-2' >{t('international_delivery_not_for', {value: partInfo && partInfo[0].ART_PRODUCT_NAME})}</span>}
                    </div>
                    {price > 0 && partInfo && partInfo[0] && PTINTL > 0 && 
                    <div className="col-md-5" style={{width: '100%'}}>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend" onClick={decrementQty}>
                          <span className="input-group-text"><i className="fas fa-minus"></i></span>
                        </div>
                        <div type="number" className="form-control no-arrows" value="1" style={{textAlign: 'center', lineHeight: '35px'}} aria-label="Amount (to the nearest dollar)" >{selectQty}</div>
                        <div className="input-group-append" onClick={incrementQty}>
                          <span className="input-group-text"><i className="fas fa-plus"></i></span>
                        </div>
                      </div>
                    </div>
                    }
                    <div className="col-md-7">
                    {price > 0 && partInfo && partInfo[0] && PTINTL > 0 && 
                      <button  onClick={() => handleAddToCart(partInfo[0])  } className="btn btn-dark add-cart mr-2 text-light " title={t('add_to_cart')} >
                        {t('add_to_cart')}
                      </button>
                      }
                    </div>
                  </div>
                 
                </div>
              </div>
              {/* End .product-single-details */}
            </div>
            {/* End .row */}
          </div>
         { partInfo && <CompatibilityCars ART_ID={partInfo[0].ART_ID} invalidPartMatch={invalidPartMatch}/>}
         
         { partInfo && <OESection ART_ID={partInfo[0].ART_ID} STR_ID={STR_ID}/>}
         <hr style={{marginTop: 0, marginBottom: '10px'}}/>
         { partInfo && <EquivalentParts STR_ID={STR_ID}/>}
         <hr style={{marginTop: 0, marginBottom: '10px'}}/>
         { partInfo && STR_ID!==null &&  <RelatedCategories STR_ID={STR_ID}/>}
         <hr style={{marginTop: 0, marginBottom: '10px'}}/>
         { partInfo && <PriceComparison STR_ID={STR_ID} PART_TITLE={`${partInfo[0].ART_SUP_BRAND} ${partInfo[0].ART_ARTICLE_NR}`}/>}
         <hr style={{marginTop: 0, marginBottom: '10px'}}/>
         
          {/* End .products-section */}
          <RecentViews />
         
          {partInfo && <Reviews title={t('have_you_ever_bought', {value: `${partInfo[0].ART_SUP_BRAND} ${partInfo[0].ART_ARTICLE_NR} ${partInfo[0].ART_PRODUCT_NAME} `})} partNumber={partInfo[0].ART_ARTICLE_NR} /> }
          <hr className="mt-0 m-b-5" />
          {/* <FooterOffers /> */}
          {/* End .row */}
        </div>
        }
        
        {/* End .container */}
      </main>
    );
}

export default PartDetails;